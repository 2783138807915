
.login-form{
//ant label css
.ant-form-item .ant-form-item-label >label{
    color: black;
    font-size: 20px;
}

//ant input csss
.ant-input-outlined{
    border-color: #A8A8A8;
    border-radius: 15px;
}
.ant-input-outlined:hover{
    border-color: #A8A8A8;
}
.ant-input-outlined:focus{
    border-color: #A8A8A8;
    box-shadow: none
}
.ant-input-outlined:focus-within{
    border-color: #A8A8A8;
    box-shadow: none
}
.ant-input{
    padding: 15px 10px;
}
.ant-input-affix-wrapper{
    padding: 15px 10px;
}

}
// ant button css
.ant-btn:hover {
    color: white !important;
    background-color: #3282FB !important;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
}