//upload  css
body {
  background: #F6F6F6;
}

.ant-upload-wrapper .ant-upload-drag {
  background-color: white;
  border: 1.5px dashed #b4b4b4;
  border-radius: 15px;
}

.ant-upload-drag p.ant-upload-text {
  color: black !important;
  font-size: 25px !important;
}

.ant-upload-drag p.ant-upload-hint {
  color: #828282 !important;
  font-size: 20px !important;
}

.ant-upload-drag-icon {
  text-align: -webkit-center;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding-top: 50px;
  padding-bottom: 30px;
}

//upload item css
.ant-upload-list-item-container {
  margin-top: 10px;
}

.ant-upload-wrapper .ant-upload-list {
  max-height: 250px;
  overflow-y: scroll;
  margin-top: 10px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  background: white;
  border-radius: 15px;
  padding: 25px 15px !important;
  margin-bottom: 10px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
  color: black;
  font-size: 20px;
  margin-top: -2px;
  padding-left: 10px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-action {
  opacity: 1 !important;
}

// .custom-height-box{
//   max-height: 400px;
//   overflow-y: scroll;
// }
// scrollbar css
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-box-shadow {
  box-shadow:
    5px 0 5px rgba(0, 0, 0, 0.05)
    /* Left */
    ,
    -5px 0 5px rgba(0, 0, 0, 0.05)
    /* Right */
    ,
    0 5px 5px rgba(0, 0, 0, 0.05)
    /* Bottom */
    ,
    0 -5px 5px rgba(0, 0, 0, 0.05);
  /* Top */
}

.ant-btn-primary {
  background-color: #3282FB !important;
}

.not-found {
  background-color: transparent;
  border: none !important;
}